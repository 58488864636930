<template>
    <div class="order">
        <div class="order-head">
            <div style="display: flex; padding-top: 20px">
                <div @click="type = 1" class="select-tag" :style="type === 1 ? 'background-color: #F2F2F2' : ''">服务订单</div>
                <div @click="type = 2" class="select-tag" :style="type === 2 ? 'background-color: #F2F2F2' : 'select-tag'">产品订单</div>
            </div>
            <div>
                <div class="hand op sc" style="width: 100%;display: flex" @click="toOption">
                    <img :src="user.headIco" style="width: 30px;height: 30px;border-radius: 50%;padding: 14px 0">
                    <div style="height: 56px;line-height: 56px;font-weight: 600; font-size: 18px; margin: 0 6px">{{user.nickName}}</div>
                </div>
            </div>
        </div>

        <div class="order-body" v-if="type === 1">
            <ServiceList></ServiceList>
        </div>

        <div class="order-body" v-if="type === 2">
            <ProduceList :type="1"></ProduceList>
        </div>

        <div class="order-body" v-if="type === 3"></div>
    </div>
</template>

<script>

import ServiceList from "./components/order/ServiceList";
import ProduceList from "./components/order/ProductList";
export default {
    name: "order",
    components: {ProduceList, ServiceList},
    data() {
        return {
            type: 1,
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
    },
    methods: {
        // 跳转
        toOption(){
            var that = this;
            if(that.user.userType === 1){
                that.$router.push('/circle/mySpace/' + that.user.uuid)
            }else if (that.user.userType === 2 || that.user.userType === 3)
                that.$router.push('/circle/organSpace/' + that.user.uuid)
        },
    }
}
</script>

<style scoped>
    .order {
        min-height: 100vh;
        background-color: #F2F2F2;
    }

    .order .order-head {
        width: calc(100% - 270px);
        height: 60px;
        color: #888888;
        font-size: 16px;
        padding: 0 60px 0 210px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .order .order-head .select-tag {
        font-size: 20px;
        font-weight: 600;
        padding: 8px 0 0 0;
        text-align: center;
        width: 200px;
        height: 48px;
        border-radius: 10px 10px 0 0;
    }

    .order .order-body {
        margin: 0 auto;
        width: 1420px;
        padding: 30px 40px;
        background-color: #FFFFFF;
    }

</style>
